import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getCustomers() {
    console.log('loading getCustomers');
    this.http
      .get<any[]>(`${environment.apiUrl}/admin/get_user_list`)
      .subscribe((data) => {
        console.log(data);
      });
  }

  getPartners() {
    this.http
      .get<any[]>(`${environment.apiUrl}/admin/get_pet_partners`)
      .subscribe((data) => {
        console.log(data);
      });
  }

  getRegisteredPets() {
    this.http
      .get<any[]>(`${environment.apiUrl}/pet/get_pet_list`)
      .subscribe((data) => {
        console.log(data);
      });
  }
  getServices() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getServices');
  }

  getOrders() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getOrders');
  }

  getPayoffs() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getPayoffs');
  }

  getInvoices() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getInvoices');
  }

  getCountryManagement() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getCountryManagement');
  }

  getBreedManagement() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getBreedManagement');
  }

  getSubscriptions() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getSubscriptions');
  }

  getCommissions() {
    // return this.http.get<any[]>(`${environment.apiUrl}/users`);
    console.log('loading getCommissions');
  }
}
