import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ngxService: NgxUiLoaderService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['/']);
    }
  }
  IsForgetCredential: boolean;
  IsConfirmed: boolean;
  returnUrl: string;
  error = '';
  useremail: any;
  password: any;
  errorUser: boolean;
  errorPassword: boolean;
  staySigned: boolean;
  useremailreset: string;
  responseMesg: string;

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.useremail = '';
    this.password = '';
    this.errorUser = false;
    this.errorPassword = false;
    this.useremailreset = '';
    this.responseMesg =
      'A verification email has been send to the registered Email Address. Please follow the instruction to reset your password';
    this.staySigned = this.getSignInStatus('user');
    if (sessionStorage.getItem('user')) {
      this.router.navigate([this.returnUrl]);
    }
  }

  changeField(flag, event) {
    switch (flag) {
      case 'useremail':
        this.useremail = event.target.value;
        break;
      case 'password':
        this.password = event.target.value;
        break;
      case 'staySign':
        this.staySigned = event.target.checked;
        break;
      case 'useremailreset':
        this.useremailreset = event.target.value;
        break;
      default:
        break;
    }
  }

  redirect() {
    this.ngxService.start();
    if (this.useremail === '') {
      this.errorUser = true;
    } else {
      this.errorUser = false;
    }
    if (this.password === '') {
      this.errorPassword = true;
    } else {
      this.errorPassword = false;
    }

    if (this.errorUser === true || this.errorPassword === true) {
      return;
    }
    this.authenticationService
      .login(this.useremail, this.password, this.staySigned)
      .pipe(first())
      .subscribe(
        (data) => {
          localStorage.setItem('token', data.token);
          if (data) {
            // redirect on success
            if (data.message) {
              this.router.navigate([this.returnUrl]);
            }
            // Show error on failure
            if (data.status === false) {
              this.error = data.errors;
              console.log(data)
              this.ngxService.stop();
            }
          }
        },
        (error) => {
          this.error = error;
          console.log(error)
          this.ngxService.stop();
        }
      );
  }

  getSignInStatus(item: string): boolean {
    return localStorage.getItem(item) ? true : false;
  }

  forgotCredential() {
    this.IsForgetCredential = true;
    this.IsConfirmed = false;
  }

  sendMail() {
    const email = this.useremailreset;
    this.authenticationService.forget(email).subscribe((data) => {
      console.log('check mailId');
      console.log(data);
      if (data) {
        if (data.status === true) {
          this.responseMesg = data.message;
        }
        if (data.status === false) {
          this.responseMesg = data.errors[0];
        }

        this.IsForgetCredential = true;
        this.IsConfirmed = true;
      }
    });
  }

  backToLogin() {
    this.IsForgetCredential = false;
    this.IsConfirmed = false;
  }
}
