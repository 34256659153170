import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  BasicAuthInterceptor,
  ErrorInterceptor,
  fakeBackendProvider
} from './helpers';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

// export function playerFactory() {
//   return player;
// }

@NgModule({
  declarations: [AppComponent, LoginComponent, DashboardComponent, DateAgoPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgxUiLoaderModule,
    NgxPaginationModule,
    TagInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBMHYIQn3cD5tM9MqRAEs_xE1H4GTzdp4A',
      libraries: ['places']
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    NgbModule
    // LottieModule.forRoot({player: playerFactory})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider
  ],
  exports: [DateAgoPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
