<section class="account-body">
  <!-- Log In page -->
  <div class="container">
    <div class="row vh-100 d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="row">
          <div class="col-lg-5 mx-auto">
            <div class="card">
              <div
                class="card-body p-0 auth-header-box"
                [ngClass]="IsForgetCredential && IsConfirmed ? 'hide' : ''"
              >
                <div class="text-center p-3">
                  <span class="logo logo-admin">
                    <!-- <img src="assets/images/logo-sm.png" height="50" alt="logo" class="auth-logo"> -->
                    <i class="header-logo"></i>
                  </span>
                </div>
              </div>
              <div class="card-body pb-0 pt-0">
                <!-- Tab panes -->
                <div class="tab-content">
                  <div
                    class="tab-pane p-3 pt-3"
                    id="LogIn_Tab"
                    role="tabpanel"
                    [ngClass]="IsForgetCredential ? '' : 'active'"
                  >
                    <form class="form-horizontal auth-form my-4">
                      <div class="form-group mb-2 row" *ngIf="error">
                        <p class="errtext">{{ error }}</p>
                      </div>
                      <!--end form-group-->

                      <div class="form-group">
                        <label for="username">Email Address</label>
                        <div class="input-group mb-3">
                          <input
                            #useremail
                            type="email"
                            class="form-control"
                            name="useremail"
                            id="useremail"
                            placeholder="Email Address"
                            (change)="changeField('useremail', $event)"
                          />
                        </div>
                        <div *ngIf="errorUser" class="invalid-feedback">
                          <p>Please enter email</p>
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group">
                        <label for="userpassword">Password</label>
                        <div class="input-group mb-3">
                          <input
                            #password
                            type="password"
                            class="form-control"
                            name="password"
                            id="userpassword"
                            placeholder="Enter password"
                            (change)="changeField('password', $event)"
                          />
                        </div>
                        <div *ngIf="errorPassword" class="invalid-feedback">
                          <p>Please enter password</p>
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group row mt-4">
                        <div class="col-sm-6">
                          <div
                            class="custom-control custom-switch switch-success"
                          >
                            <input
                              #staySign
                              type="checkbox"
                              class="custom-control-input"
                              id="staySign"
                              [checked]="staySigned"
                              (change)="changeField('staySign', $event)"
                            />
                            <label
                              class="custom-control-label text-muted"
                              for="staySign"
                              >Stay Signed In</label
                            >
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-sm-6 text-right pt-1">
                          <span
                            class="text-muted font-13 cursor-hand"
                            (click)="forgotCredential()"
                          >
                            <i class="dripicons-lock"></i>
                            Forgot password?
                          </span>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->

                      <div class="form-group mb-0 row">
                        <div class="col-12 mt-2">
                          <button
                            class="btn btn-primary btn-block waves-effect waves-light"
                            type="button"
                            (click)="redirect()"
                          >
                            Log In
                          </button>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->
                    </form>
                    <!--end form-->
                  </div>

                  <div
                    class="tab-pane px-3 pt-3"
                    id="Register_Tab"
                    role="tabpanel"
                    [ngClass]="
                      IsForgetCredential && !IsConfirmed ? 'active' : ''
                    "
                  >
                    <form class="form-horizontal auth-form my-4">
                      <div class="form-group">
                        <label for="useremail">Email Address</label>
                        <div class="input-group mb-3">
                          <input
                            #useremailreset
                            type="email"
                            class="form-control"
                            name="useremailreset"
                            id="useremailreset"
                            placeholder="Email Address"
                            (change)="changeField('useremailreset', $event)"
                          />
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group mb-0 row">
                        <div class="col-12 mt-2">
                          <button
                            class="btn btn-primary btn-block waves-effect waves-light"
                            type="button"
                            (click)="sendMail()"
                          >
                            Reset Password
                          </button>
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group-->
                    </form>
                    <!--end form-->
                    <p class="mb-10 text-muted text-center">
                      <span
                        class="text-primary ml-2 cursor-hand"
                        (click)="backToLogin()"
                        >Go Back to Login</span
                      >
                    </p>
                  </div>

                  <div
                    class="tab-pane emailConfrm"
                    id="Confirmation_Tab"
                    role="tabpanel"
                    [ngClass]="
                      IsForgetCredential && IsConfirmed ? 'active' : ''
                    "
                  >
                    <p class="mb-10">{{ responseMesg }}</p>
                    <p class="mb-10 text-muted text-center">
                      <span
                        class="text-primary ml-2 cursor-hand"
                        (click)="backToLogin()"
                        >Go Back to Login</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <!--end card-body-->
            </div>
            <!--end card-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <div style="position: relative">
    <ngx-ui-loader></ngx-ui-loader>
  </div>
</section>
