<!-- Left Sidenav -->
<div class="left-sidenav">
    <!-- LOGO -->
    <span class="">
      <i class="header-logo dashboard-logo"></i>
    </span>
    <!--new Sidenav-->
    <div class="menu-content h-100" data-simplebar>
        <ul class="metismenu left-sidenav-menu">
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'dashboard' ? 'active' : ''"
                        routerLink=""
                        (click)="loadView('home')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\dashboard.svg"/><span
                        class="clrwhite"
                >Dashboard</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'customer' ? 'active' : ''"
                        routerLink="customer"
                        (click)="loadView('customer')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\customer.svg"/><span
                        class="clrwhite"
                >Customers</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'manage-partners' ? 'active' : ''"
                        routerLink="manage-partners"
                        (click)="loadView('manage-partners')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\partners.svg"/><span
                        class="clrwhite"
                >Partners Management</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'tags' ? 'active' : ''"
                        routerLink="tags"
                        (click)="loadView('tags')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\partners.svg"/>
                    <span class="clrwhite">Tags</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'registered_pets' ? 'active' : ''"
                        routerLink="registered_pets"
                        (click)="loadView('registered_pets')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\pets.svg"/><span
                        class="clrwhite"
                >Registered Pets</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'user_service' ? 'active' : ''"
                        routerLink="user_service"
                        (click)="loadView('user_service')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\services.svg"/><span
                        class="clrwhite"
                >Services</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'orders' ? 'active' : ''"
                        routerLink="orders"
                        (click)="loadView('orders')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\orders.svg"/><span
                        class="clrwhite"
                >Orders</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'store' ? 'active' : ''"
                        routerLink="store"
                        (click)="loadView('store')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\dashboard.svg"/><span
                        class="clrwhite"
                >Store</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'payoffs' ? 'active' : ''"
                        routerLink="payoffs"
                        (click)="loadView('payoffs')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\payoffs.svg"/><span
                        class="clrwhite"
                >Payoffs</span
                ></a
                >
            </li>
            <!--    <li class="nav-item"><a class="nav-link disabled-btn" [ngClass]="(currentRoute == '') ? 'active': ''" routerLink="" (click)="loadView('invoices')"><i class="align-self-center menu-icon"></i><img src="assets\images\dashboard-icons\invoices.svg"><span class="clrwhite">Invoices</span></a></li>-->
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'countries' ? 'active' : ''"
                        routerLink="countries"
                        (click)="loadView('countries')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\country.svg"/><span
                        class="clrwhite"
                >Country</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'breed' ? 'active' : ''"
                        routerLink="breed"
                        (click)="loadView('breed')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\breed.svg"/><span
                        class="clrwhite"
                >Breed</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'notifications' ? 'active' : ''"
                        routerLink="notifications"
                        (click)="loadView('notifications')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\notifications.svg"/><span
                        class="clrwhite"
                >Notifications</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'offers' ? 'active' : ''"
                        routerLink="offers"
                        (click)="loadView('offers')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\offers.svg"/><span
                        class="clrwhite"
                >Offers</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'promo_code' ? 'active' : ''"
                        routerLink="promo_code"
                        (click)="loadView('promo_code')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\promoCode.svg"/><span
                        class="clrwhite"
                >Promo Code</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'subscription' ? 'active' : ''"
                        routerLink="subscription"
                        (click)="loadView('subscription')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\subscriptions.svg"/><span
                        class="clrwhite"
                >Subscriptions</span
                ></a
                >
            </li>
            <li class="nav-item">
                <a
                        class="nav-link"
                        [ngClass]="currentRoute === 'support' ? 'active' : ''"
                        routerLink="support"
                        (click)="loadView('support')"
                ><i class="align-self-center menu-icon"></i
                ><img src="assets\images\dashboard-icons\support.svg"/><span
                        class="clrwhite"
                >Support</span
                ></a
                >
            </li>
        </ul>
    </div>
    <!--end sidenav-->
</div>

<div class="page-wrapper">
    <div class="page-content">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ pageHeading | titlecase }}</h4>
                                <breadcrumb #parent>
                                    <ol class="breadcrumb">
                                        <ng-template
                                                ngFor
                                                let-route
                                                [ngForOf]="parent.breadcrumbs"
                                                let-i="index"
                                                let-cnt="count"
                                        >
                                            <li *ngIf="cnt - 1 !== i" class="breadcrumb-item">
                                                <a
                                                        href="{{ route.url }}"
                                                        (click)="loadView(route.displayName)"
                                                >{{ route.displayName }}</a
                                                >
                                            </li>
                                            <li *ngIf="cnt - 1 === i" class="breadcrumb-item active">
                                                {{ route.displayName }}
                                            </li>
                                            <!--                      <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>-->

                                            <!--                      <li *ngIf="pageHeading !== 'Dashboard'" class="breadcrumb-item active">{{pageHeading}}</li>-->
                                        </ng-template>
                                    </ol>
                                </breadcrumb>
                            </div>
                        </div>
                        <!--end row-->
                    </div>
                    <!--end page-title-box-->
                </div>
                <!--end col-->
            </div>
            <router-outlet></router-outlet>
        </div>
        <!-- container -->
    </div>
    <!-- end page content -->
</div>
<!-- end page-wrapper -->
