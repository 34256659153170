// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://staging-api.petsrwise.com/api/v1'
  // apiUrl: 'http://ec2-54-211-201-194.compute-1.amazonaws.com:3000/dev/api/v1',
  //apiUrl: 'http://localhost:3002/api/v1',
  // apiUrl: 'https://staging-apis.petsrwise.com/dev/api/v1'
  //products:'https://dev.api.petsrwise.com/api',
  apiUrl: 'https://beta-apis.petsrwise.com/api/v1',

  // PROD TEST
  products:'https://prod.api.actual.petsrwise.com/api',
};
