import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  pageHeading: string;
  currentRoute: any = '';
  constructor(
    private rout: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const path = this.router.url.split('/')[2];
    this.loadView(path);
  }

  logout(e) {
    console.log('logout');
    this.authenticationService.logout();
  }

  loadView(view: string) {
    switch (view) {
      case 'home':
        this.pageHeading = 'Dashboard';
        this.currentRoute = '/';
        break;
      case 'customer':
        // this.userService.getCustomers();
        this.pageHeading = 'Customers';
        this.currentRoute = 'customer';
        break;
      case 'registered_pets':
        // this.userService.getRegisteredPets();
        this.pageHeading = 'Registered Pets';
        this.currentRoute = 'registered_pets';
        break;
      case 'partners':
        // this.userService.getPartners();
        this.pageHeading = 'Partners';
        this.currentRoute = 'partners';
        break;
      case 'tags':
        // this.userService.getPartners();
        this.pageHeading = 'Tags';
        this.currentRoute = 'tags';
        break;
      case 'manage-partners':
        // this.userService.getPartners();
        this.pageHeading = 'Partners Management';
        this.currentRoute = 'manage-partners';
        break;
      case 'user_service':
        // this.userService.getServices();
        this.pageHeading = 'Services';
        this.currentRoute = 'user_service';
        break;
      case 'orders':
        // this.userService.getOrders();
        this.pageHeading = 'Orders';
        this.currentRoute = 'orders';
        break;
      case 'payoffs':
        // this.userService.getPayoffs();
        this.pageHeading = 'Payoffs';
        this.currentRoute = 'payoffs';
        break;
      case 'invoices':
        // this.userService.getInvoices();
        this.pageHeading = 'Invoices';
        this.currentRoute = 'invoices';
        break;
      case 'countries':
        // this.userService.getCountryManagement();
        this.pageHeading = 'Country';
        this.currentRoute = 'countries';
        break;
      case 'breed':
        // this.userService.getBreedManagement();
        this.pageHeading = 'Breed';
        this.currentRoute = 'breed';
        break;
      case 'subscription':
        // this.userService.getSubscriptions();
        this.pageHeading = 'Subscriptions';
        this.currentRoute = 'subscription';
        break;
      case 'offers':
        // this.userService.getSubscriptions();
        this.pageHeading = 'offers';
        this.currentRoute = 'offers';
        break;
      case 'promo_code':
        // this.userService.getSubscriptions();
        this.pageHeading = 'Promo Code';
        this.currentRoute = 'promo_code';
        break;
      case 'notifications':
        // this.userService.getCommissions();
        this.pageHeading = 'Notifications';
        this.currentRoute = 'notifications';
        break;
      case 'support':
        // this.userService.getCommissions();
        this.pageHeading = 'Support';
        this.currentRoute = 'support';
        break;
      case 'users':
        // this.userService.getCommissions();
        this.pageHeading = 'Users';
        this.currentRoute = 'users';
        break;
      case 'payment_reports':
        // this.userService.getCommissions();
        this.pageHeading = 'Payment Reports';
        this.currentRoute = 'payment_reports';
        break;
      case 'referral_reports':
        // this.userService.getCommissions();
        this.pageHeading = 'Referral Reports';
        this.currentRoute = 'referral_reports';
        break;
      case 'wallet_reports':
        // this.userService.getCommissions();
        this.pageHeading = 'Partner Wallet Reports';
        this.currentRoute = 'wallet_reports';
        break;
      case 'cancellation_reports':
        // this.userService.getCommissions();
        this.pageHeading = 'Cancellation Reports';
        this.currentRoute = 'cancellation_reports';
        break;
      default:
        this.pageHeading = view || 'Dashboard';
        if (!view) {
          this.currentRoute = 'dashboard';
        }
        break;
    }
  }
}
